interface LastVerifiedResponse {
  lastVerified: string; // ISO 8601 string representing the verification timestamp
}
import axios from 'axios';

/**
 * Checks if the node verification is valid.
 * @param publicKey - The public key of the user to verify.
 * @returns A promise that resolves to `true` if the verification is valid, otherwise `false`.
 */
export const checkNodeVerification = async (
  publicKey: string
): Promise<boolean> => {
  try {
    // Fetch the last verified timestamp from the backend
    const response = await axios.get<LastVerifiedResponse>(
      `${process.env.REACT_APP_BACKEND_URL}/last-verified/${publicKey}`
    );

    const { lastVerified } = response.data;

    // Parse the lastVerified timestamp into a Date object
    const lastVerifiedDate = new Date(lastVerified);
    const now = new Date();

    // Calculate the difference in seconds
    const diffInSeconds = (now.getTime() - lastVerifiedDate.getTime()) / 1000;

    // Check if the difference is less than or equal to 60 seconds
    return diffInSeconds <= 60;
  } catch (error) {
    console.error('Error checking verification status:', error);
    return false;
  }
};
