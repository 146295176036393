import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { router } from 'routes';
import { WalletContextProvider } from 'contexts';
import { RECAPTCHA_SITE_KEY } from 'config';
import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

console.log(RECAPTCHA_SITE_KEY || 'Key not loaded');

root.render(
  <StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_SITE_KEY}
      useEnterprise={false}
    >
      <QueryClientProvider client={queryClient}>
        <WalletContextProvider>
          <RouterProvider router={router} />
        </WalletContextProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  </StrictMode>
);

reportWebVitals();
