import { FunctionComponent, useEffect, useState } from 'react';
import { GoBackIcon } from 'assets';
import { Button } from 'components';
import { verifyTwitter } from 'services';
import { MethodValidationProps } from 'types';
import { useMutation } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { useWalletContext } from 'contexts';
import loadingIcon from 'assets/loading-animation.gif';
import { verifyIfJwtHasExpired } from 'utils';
import { useReauthentication } from 'hooks';
import { API_URL } from 'config';
import ReCAPTCHA from 'react-google-recaptcha';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const TwitterValidation: FunctionComponent<MethodValidationProps> = ({
  onSuccess,
  publicKey,
}) => {
  const [error, setError] = useState<string>('');
  const [hasTweeted, setHasTweeted] = useState<boolean>(false);
  const [hasRefreshedJwt, setHasRefreshedJwt] = useState<boolean>(false);
  const [cookies] = useCookies(['_ga_KC4FPRD34W']);
  const [successStatus, setSuccessStatus] = useState(false);
  const [shouldDisplayRecaptcha, setShouldDisplayRecaptcha] = useState(false);

  const { ownReferralCode, setOwnReferralCode } = useWalletContext();

  // If not found, set referral code here:
  async function fetchReferralCode() {
    try {
      console.log('hey');
      const response = await fetch(`${API_URL}/get-referral-code/${publicKey}`);
      const data = await response.json();
      setOwnReferralCode(data.code);
    } catch (error) {
      console.error('Failed to fetch referral code:', error);
    }
  }

  useEffect(() => {
    fetchReferralCode();
  }, []);

  // Example usage:
  // fetchReferralCode('your_api_url_here', 'your_public_key_here');

  let user_cookie: string;
  useEffect(() => {
    try {
      if (cookies['_ga_KC4FPRD34W']) {
        user_cookie = cookies['_ga_KC4FPRD34W'];
      }
    } catch (error) {
      console.error("Error parsing 'user-info' cookie:", error);
    }
  }, [cookies]);

  const tweetText = `I just joined the Koii Network! Turn any computer into a passive income generating node in 5 minutes. Join me and let's build the future together: https://www.koii.network/node?refCode=${ownReferralCode}. My address: ${publicKey}`;
  const url = `https://twitter.com/intent/tweet?text=${tweetText}`;
  const buttonText = hasTweeted ? 'Check Tweet' : 'Send a Tweet';

  const goBack = () => {
    setError('');
    setHasTweeted(false);
  };

  const tweet = () => {
    setError('');

    window.open(url, undefined, 'height=600,width=600');
    setHasTweeted(true);
  };

  const timesToRetryValidation = hasTweeted ? 0 : 0;

  const { reauthenticate } = useReauthentication(publicKey);

  const { mutate: validateTweet, isLoading: isVerifying } = useMutation({
    mutationFn: () => verifyTwitter(publicKey),
    onSuccess: () => {
      setSuccessStatus(true);
      setError('');
      window.dataLayer.push({
        event: 'twitter_verification',
        event_name: 'twitter_verification',
        user_public_key: publicKey,
        user_cookie: user_cookie,
      });
      onSuccess();
    },
    onError: async (initialError: any) => {
      const isJwtExpired = verifyIfJwtHasExpired(initialError);
      const genericErrorMessage = 'Twitter verification failed.';
      if (isJwtExpired && !hasRefreshedJwt) {
        try {
          // await reauthenticate();
          // validateTweet();
          // setHasRefreshedJwt(true);
          setShouldDisplayRecaptcha(true);
          throw new Error('Authentication expired');
        } catch (retryError: any) {
          setError(retryError?.response?.data?.message || genericErrorMessage);
        }
      } else {
        setError(initialError.response?.data?.message || genericErrorMessage);
      }
    },
    retry(failureCount, error) {
      console.log('Retry!');
      const isJwtExpired = verifyIfJwtHasExpired(error);
      if (isJwtExpired) {
        return false;
      } else {
        return failureCount < timesToRetryValidation;
      }
    },
    retryDelay: 3000,
  });

  const handleVerifyRecaptcha = async (token: string | null) => {
    console.log('handleVerifyRecaptcha');
    await reauthenticate(token);
    setHasRefreshedJwt(true);
    setShouldDisplayRecaptcha(false);
    setError('');
    validateTweet();
  };

  const handleClickButton = hasTweeted ? () => validateTweet() : tweet;

  return (
    <div className="flex flex-col gap-8 items-center">
      {hasTweeted && (
        <GoBackIcon
          className="absolute left-3 top-3 cursor-pointer"
          onClick={goBack}
        />
      )}

      <p className="text-2xl mt-3">Twitter Verification</p>
      <p className="text-sm max-w-xs">
        Post a tweet to verify your account and continue building reputation.
        Come back here to validate once you&apos;re done.
      </p>

      {shouldDisplayRecaptcha ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
          onChange={handleVerifyRecaptcha}
        />
      ) : successStatus ? (
        <div className="success-message">Success!</div> // Display a success message
      ) : isVerifying && hasTweeted ? (
        <img src={loadingIcon} className="w-14 h-14 mx-auto" alt="loading" />
      ) : (
        <Button
          onClick={handleClickButton}
          className="w-48 m-auto h-14 !p-0 text-base"
          disabled={isVerifying}
        >
          {buttonText}
        </Button>
      )}

      {/* We also run the validation on mount (see useEffect) but we don't display the error in that case, so we check hasTweeted */}
      {error && hasTweeted && (
        <p className="text-error text-xs -mb-12 max-w-xs">
          Tweet verification failed. Try again {error}
        </p>
      )}
    </div>
  );
};
