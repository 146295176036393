import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { authenticateUser } from 'services';

export const useReauthentication = (publicKey: string) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const reauthenticate = async (recaptchaToken: string | null) => {
    if (!recaptchaToken) throw 'Catpcha verification failed. Try again.';
    await authenticateUser(recaptchaToken, publicKey);
  };

  return { reauthenticate };
};
