import { FunctionComponent } from 'react';
import { MethodValidationProps } from 'types';
import { ReferralGraphic } from 'assets';

export const ReferralTile: FunctionComponent<MethodValidationProps> = ({
  onSuccess,
  publicKey,
}) => {
  return (
    <div className="m-auto px-4">
      <ReferralGraphic className="mx-auto" />
      <div className="flex flex-col gap-2">
        <p className="text-2xl mt-3">5 KOII for you</p>
        <p className="text-sm max-w-xs">
          Verify your Twitter or Discord, then immediately get 5 extra tokens
          with a referral code.
        </p>
      </div>
    </div>
  );
};
