import {
  FunctionComponent,
  useState,
  useCallback,
  ChangeEventHandler,
} from 'react';
import { GoBackIcon } from 'assets';
import { Button, TextInput, CodeInput } from 'components';
import { sendEmailCode, submitEmailCode } from 'services';
import { MethodValidationProps } from 'types';
import { isValidEmail, parseAxiosError, verifyIfJwtHasExpired } from 'utils';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { CheckBox } from './common/CheckBox';
import { useReauthentication } from 'hooks';
import ReCAPTCHA from 'react-google-recaptcha';

export const EmailValidation: FunctionComponent<MethodValidationProps> = ({
  onSuccess,
  publicKey,
}) => {
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [hasSentCode, setHasSentCode] = useState<boolean>(false);
  const [acceptedSubscribeToNewsletter, setAcceptedSubscribeToNewsletter] =
    useState<boolean>(false);
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [functionToRetry, setFunctionToRetry] = useState<
    'receiveCode' | 'validateCode'
  >();
  const [shouldDisplayRecaptcha, setShouldDisplayRecaptcha] = useState(false);

  const [cookies] = useCookies(['_ga_KC4FPRD34W']);
  let user_cookie = '';

  useEffect(() => {
    try {
      if (cookies['_ga_KC4FPRD34W']) {
        user_cookie = cookies['_ga_KC4FPRD34W'];
      }
    } catch (error) {
      console.error("Error parsing 'user-info' cookie:", error);
    }
  }, [cookies]);

  const text = hasSentCode ? (
    <>
      <p>Check the code sent to</p>
      <p className="font-bold">{email}</p>
    </>
  ) : (
    'To verify your email, we will send a code to your address.'
  );
  const buttonText = hasSentCode ? 'Verify Email' : 'Send Code';

  const handleChangeEmail: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setEmail(value);
  };

  const handleChangeSubscribeToNewsletter: ChangeEventHandler<
    HTMLInputElement
  > = ({ target: { checked } }) => {
    setAcceptedSubscribeToNewsletter(checked);
  };

  const submitHubspotForm = async () => {
    const hubspotFormId = 'b31f31bd-fffc-4ddb-84ff-f2448a776773';
    const hubspotPortalId = '20249188';
    const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`;
    fetch(hubspotUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fields: [
          {
            name: 'email',
            value: email,
          },
        ],
      }),
    });
  };

  const receiveCode = useCallback(async () => {
    await sendEmailCode(email, publicKey);
    setHasSentCode(true);
  }, [email, publicKey]);

  const { reauthenticate } = useReauthentication(publicKey);

  const handleVerifyRecaptcha = async (token: string | null) => {
    console.log('handleVerifyRecaptcha');
    await reauthenticate(token);
    setShouldDisplayRecaptcha(false);
    setError('');
    const callback =
      functionToRetry === 'receiveCode' ? receiveCode : validateCode;
    await callback?.();
  };

  const receiveCodeWithRetry = async () => {
    if (isValidEmail(email)) {
      setError('');
    } else {
      setError('That’s not a valid email address');
      return;
    }
    try {
      await receiveCode();
    } catch (initialError) {
      try {
        const isJwtExpired = verifyIfJwtHasExpired(initialError);
        if (isJwtExpired) {
          setFunctionToRetry('receiveCode');
          setShouldDisplayRecaptcha(true);
          throw new Error('Authentication expired');
        } else {
          throw initialError;
        }
      } catch (retryError: any) {
        const errorMessage =
          parseAxiosError(
            retryError,
            "You didn't get KOII from faucet as you already received",
            'That email has already been used'
          ) ||
          parseAxiosError(
            retryError,
            'Email provider is not trusted',
            'Please use a trusted provider (Gmail, Yahoo, Proton, etc.)'
          ) ||
          retryError?.response?.data?.message ||
          retryError.message ||
          'Email verification failed. Try again';

        setError(errorMessage);
      }
      if (acceptedSubscribeToNewsletter)
        try {
          submitHubspotForm();
        } catch (error) {
          console.error(error);
        }
    }
  };

  const goBack = () => {
    setError('');
    setCode('');
    setHasSentCode(false);
  };

  const reSendCode = () => {
    setError('Code resent');
    setCode('');
    receiveCode();
  };

  const validateCode = useCallback(async () => {
    await submitEmailCode(code, publicKey, email);
    onSuccess();
    window.dataLayer.push({
      event: 'email_verification',
      event_name: 'email_verification',
      user_public_key: publicKey,
      user_email: email,
      user_cookie: user_cookie,
    });
  }, [onSuccess, publicKey, code, email, user_cookie]);

  const validateCodeWithRetry = useCallback(async () => {
    try {
      setIsValidatingCode(true);
      await validateCode();
    } catch (initialError) {
      try {
        const isJwtExpired = verifyIfJwtHasExpired(initialError);
        if (isJwtExpired) {
          setFunctionToRetry('validateCode');
          setShouldDisplayRecaptcha(true);
          throw new Error('Authentication expired');
        } else {
          throw initialError;
        }
      } catch (retryError: any) {
        const errorMessage =
          parseAxiosError(
            retryError,
            'Invalid verification code',
            'Incorrect or expired code'
          ) ||
          retryError.response?.data.message ||
          'Code verification failed';

        setError(errorMessage);
      }
    } finally {
      setTimeout(() => {
        setIsValidatingCode(false);
      }, 2000);
    }
  }, [validateCode]);

  const handleClickButton = hasSentCode
    ? validateCodeWithRetry
    : receiveCodeWithRetry;

  return (
    <div className="flex flex-col justify-between items-center mt-6 gap-7">
      {hasSentCode && (
        <GoBackIcon
          className="absolute left-3 top-3 cursor-pointer"
          onClick={goBack}
        />
      )}

      <p className="text-2xl mt-3 font-semibold">Email Verification</p>
      <div className="text-sm max-w-[17rem] m-auto">{text}</div>

      {hasSentCode ? (
        <CodeInput code={code} setCode={setCode} />
      ) : (
        <TextInput
          placeholder="Type email address"
          onChange={handleChangeEmail}
          className="w-60 sm:w-80"
        />
      )}

      {!hasSentCode && (
        <div className="flex gap-4 items-center w-72 sm:w-80 -mt-1 mb-1">
          <CheckBox
            onChange={handleChangeSubscribeToNewsletter}
            id="subscribe-newsletter"
          />
          <label htmlFor="subscribe-newsletter" className="text-sm text-left">
            Stay up to date with our newsletter. We won&apos;t spam you or sell
            your information.
          </label>
        </div>
      )}

      {/.proton.me$/.test(email) && (
        <p className="text-error text-xs max-w-[20rem]">
          Please be aware that the Proton address may restricted from
          registering on third-party services
        </p>
      )}
      {error && <p className="text-error text-xs -my-5 h-3">{error}</p>}

      {shouldDisplayRecaptcha ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
          onChange={handleVerifyRecaptcha}
        />
      ) : (
        <Button
          onClick={handleClickButton}
          className={`w-46 mt-2 h-14 !p-0 text-base ${
            /.proton.me$/.test(email) ? 'mb-8' : 'mb-auto'
          }`}
          disabled={isValidatingCode}
        >
          {buttonText}
        </Button>
      )}

      {hasSentCode && (
        <div className="flex flex-col">
          <button
            onClick={reSendCode}
            className="text-center text-xs underline mb-2"
          >
            Send Again
          </button>

          <a
            href="https://discord.gg/koii-network"
            className="text-center text-xs underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Issues? Reach us on Discord
          </a>
        </div>
      )}
    </div>
  );
};
